<template>
    <div class="pay-page">
        <div v-if="ticketInfo">
            <van-cell-group>
                <!-- 活动 -->
                <van-cell-group>
                    <van-cell :value="ticketInfo.activity_name" class="cell-top" value-class="title2">
                        <template slot="title">
                            <van-image
                                    round
                                    width="0.8rem"
                                    height="0.8rem"
                                    :src="ticketInfo.banner_image"
                                    fit="cover"
                            />
                        </template>
                    </van-cell>
                </van-cell-group>
                <van-cell title="订单编号">
                    <div slot="default">
                        <div class="left"></div>
                        <div class="right">{{ticketInfo.order_sn}}</div>
                    </div>
                </van-cell>
                <van-cell title="创建时间">
                    <div slot="default">
                        <div class="left">{{ticketInfo.createtime}}</div>
                    </div>
                </van-cell>

                <van-collapse v-model="activeNames">
                    <van-collapse-item title="报名信息" name="1">
                        <van-cell title="姓名">
                            <div slot="default">
                                <div class="left">{{ticketInfo.linkman}}</div>
                            </div>
                        </van-cell>
                        <van-cell title="电话">
                            <div slot="default">
                                <div class="left">{{ticketInfo.phone}}</div>
                            </div>
                        </van-cell>
                    </van-collapse-item>
                </van-collapse>

                <van-cell title="门票种类">
                    <div slot="default">
                        <div class="left">{{ticketInfo && ticketInfo.card_info.card_name}}</div>
                        <div class="right text-theme3">
                            {{ticketInfo.card_info.amount == 0 ? '免费' : '￥' + ticketInfo.card_info.amount}}
                        </div>
                    </div>
                </van-cell>

                <van-cell title="备注" value-class="flex-5">
                    <div slot="default">
                        <div class="left">{{ticketInfo.note}}</div>
                    </div>
                </van-cell>
            </van-cell-group>

            <div class="btn-wrap">
                <van-button type="info" class @click="toPay" block>确定支付</van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {Cell, CellGroup, Collapse, CollapseItem, Button} from "vant";
    import {get_ticket_detail} from "@/request/api";

    import wx from "@/request/utils/wechat";//微信JSSDK


    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            [Button.name]: Button
        },
        data() {
            return {
                activeNames: ["1"],
                ticketInfo: {
                    id: 11,
                    linkname: "测试数据",
                    money: "￥1",
                    phone: "1546654654",
                    createtime: 1578049156,
                    sell_id: "1号",
                    get_id: "1号"
                },
                cardInfo: {},
                activityInfo: {},
				
				back: 0,
				goods: '',
				activity_id: ''
            };
        },
        mounted: function () {
            //获取售票详情id
			if (this.$route.query.back) {
				this.back = this.$route.query.back
				this.goods = this.$route.query.goods
			}
			this.activity_id = this.$route.query.activity_id
            this.loadData();
        },
        methods: {
            loadData() {
                this.showLoading("加载中", true);

                get_ticket_detail({id: this.$route.query.ticket_id})
                    .then(res => {
                        this.hideLoading();
                        if (res.code == 1) {
                            this.ticketInfo = res.data;
                            //处理邮箱、地址
                            this.ticketInfo.enroll_json = JSON.stringify(
                                this.ticketInfo.enroll_json
                            );
                        } else {
                            this.$dialog({message: res.msg});
                        }
                    }).catch(err => {
                    this.hideLoading();
                    this.$dialog({message: "请求有误,请重新刷新页面"});
                });
            },
            toPay() {
                this.$router.push({
                	path: '/payment/method',
                	query: {
                		id: this.ticketInfo.id,
                		type: 'ticket'
                	}
                })
            }
        }
    };
</script>

<style lang="less">
    .pay-page {
        .van-collapse-item__content {
            padding: 0 10px !important;
        }
        .cell-top {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            .title2 {
                color: #000 !important;
            }

            .van-cell__title {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                .van-image {
                    margin-right: 5px;
                }
            }
        }
        .flex-5 {
            flex: 5;
        }
        .img-cell-title {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            .text-wrap {
                max-width: 180px;
                display: flex;
                flex-flow: column nowrap;
                margin: 0 5px;
            }
            .img-cell-status {
                color: green;
            }
        }
        .btn-wrap {
            margin: 30px;
        }
    }
</style>